import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Vytas Quint is the son of `}<a parentName="p" {...{
        "href": "/Enzo%20Quint",
        "title": "Enzo Quint"
      }}>{`Enzo Quint`}</a>{` and `}<a parentName="p" {...{
        "href": "/Ari%20Vochard",
        "title": "Ari Vochard"
      }}>{`Ari Vochard`}</a>{`. He and Enzo had a falling out when Vytas was young. Enzo wanted his son to follow in his father's footsteps and persue a career at the `}<a parentName="p" {...{
        "href": "/The%20Basentia%20Omnia",
        "title": "The Basentia Omnia"
      }}>{`The Basentia`}</a>{`, but Vytas wanted to join `}<a parentName="p" {...{
        "href": "/The%20Argent",
        "title": "The Argent"
      }}>{`The Argent`}</a>{` and become a `}<a parentName="p" {...{
        "href": "/Missary",
        "title": "Missary"
      }}>{`Missary`}</a>{` for the church. He is still on speaking terms with Ari, but Vytas had a hard time growing up accepting his fathers, longing to know his biological parents.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      